import CloseIcon from '@mui/icons-material/Close';
import {
	Badge,
	Box,
	IconButton,
	ListItem,
	Typography
} from "@mui/material";
import { formatDistance } from "date-fns";
import { tr } from "date-fns/locale";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { HubContext } from "src/contexts/HubContextProvider";

interface INotificationItemProps {
	ntf: INotification
}

function NotificationsItem(props: INotificationItemProps) {

	const hubCtx = useContext(HubContext);
	const { t } = useTranslation();
	const navigate = useNavigate();

	const redirect = () => {
		if (props.ntf.type === "DraftAdded") {
			navigate("/products");
		} else if (props.ntf.type === "MissingCategory" || props.ntf.type === "MissingBrand") {
			navigate("/suggestions");
		}
	}

	return (
		<ListItem button
			onMouseOver={() => hubCtx.setRead(props.ntf.idempotencyKey)}
			onClick={() => redirect()}
			sx={{
				px: 2,
				minWidth: 350,
				display: { xs: "block", sm: "flex" }
			}}
			secondaryAction={!props.ntf.read && <Badge color="success" variant="dot" />}
		>
			<Box flex="1" sx={{
				maxWidth: "350px",
				overflow: "hidden",
				textOverflow: "ellipsis",
				whiteSpace: "nowrap",
			}}>
				<Box display="flex" justifyContent="space-between">
					<Typography sx={{ fontWeight: "bold" }}>
						{t(props.ntf.type + "Header")}
					</Typography>
					<Box display="flex" alignItems="center">
						<Typography variant="caption" sx={{ textTransform: "none", mr: 1 }}>
							{formatDistance(props.ntf.createdAt, new Date(), {
								addSuffix: true,
								locale: tr
							})}
						</Typography>
						<IconButton size="small" onClick={() => hubCtx.removeNotification(props.ntf.idempotencyKey)}>
							<CloseIcon fontSize="small" />
						</IconButton>
					</Box>
				</Box>
				<Typography
					component="span"
					variant="body2"
					color="text.secondary"
				>
					{" "}
					{t(props.ntf.type + "Body", props.ntf.payload as string)}
				</Typography>
			</Box>
		</ListItem>
	);
}

export default NotificationsItem;
