import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from 'react';
import { useTranslation } from "react-i18next";

interface IFeatureCardProps {
	icon: JSX.Element;
	title: string;
	body: string;
}

function FeatureCard(props: IFeatureCardProps) {
	const theme = useTheme();
	const { t } = useTranslation();

	return <Grid item lg={3} xs={12} container justifyContent="center">
		<Card sx={{
			maxWidth: 200, minWidth: 300,
			margin: theme.spacing(2),
			paddingBottom: 0, display: "flex",
			flexDirection: "column",
			justifyContent: "space-between",
			borderRadius: 0
		}}>
			<CardContent>
				<Box display="flex" justifyContent="center" alignItems="center">
					{React.cloneElement(props.icon, { sx: { color: theme.colors.success.main, fontSize: "40px" } })}
				</Box>
				<Box display="flex" justifyContent="center" alignItems="center">
					<Typography variant="h4" sx={{ color: 'text.secondary' }}>
						{props.title}
					</Typography>
				</Box>
				<Box display="flex" justifyContent="center" alignItems="center">
					<Typography variant="body2" sx={{ color: 'text.secondary' }}>
						{props.body}
					</Typography>
				</Box>
			</CardContent>
		</Card>
	</Grid>;
}

export default FeatureCard;
