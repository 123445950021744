import ExpandMoreTwoToneIcon from "@mui/icons-material/ExpandMoreTwoTone";
import {
	Box,
	List,
	ListItem,
	ListItemText,
	Menu,
	MenuItem
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const ListWrapper = styled(Box)(
	({ theme }) => `
        .MuiTouchRipple-root {
            display: none;
        }
        
        .MuiListItem-root {
            transition: ${theme.transitions.create(["color", "fill"])};
            
            &.MuiListItem-indicators {
                padding: ${theme.spacing(1, 2)};
            
                .MuiListItemText-root {
                    .MuiTypography-root {
                        &:before {
                            height: 4px;
                            width: 22px;
                            opacity: 0;
                            visibility: hidden;
                            display: block;
                            position: absolute;
                            bottom: -10px;
                            transition: all .2s;
                            border-radius: ${theme.general.borderRadiusLg};
                            content: "";
                            background: ${theme.colors.primary.main};
                        }
                    }
                }

                &.active,
                &:active,
                &:hover {
                
                    background: transparent;
                
                    .MuiListItemText-root {
                        .MuiTypography-root {
                            &:before {
                                opacity: 1;
                                visibility: visible;
                                bottom: 0px;
                            }
                        }
                    }
                }
            }
        }
`
);

function HeaderMenu() {
	const { t } = useTranslation();
	const ref = useRef<any>(null);
	const [isOpen, setOpen] = useState<boolean>(false);

	const handleOpen = (): void => {
		setOpen(true);
	};

	const handleClose = (): void => {
		setOpen(false);
	};

	return (
		<>
			<ListWrapper
				sx={{
					display: {
						xs: "none",
						md: "block"
					}
				}}
			>
				<List disablePadding component={Box} display="flex">
					<ListItem
						classes={{ root: "MuiListItem-indicators" }}
						button
						component={NavLink}
						to="/dashboard"
					>
						<ListItemText
							primaryTypographyProps={{ noWrap: true }}
							primary={t("HomePage")}
						/>
					</ListItem>
					<ListItem
						classes={{ root: "MuiListItem-indicators" }}
						button
						component={NavLink}
						to="/products"
					>
						<ListItemText
							primaryTypographyProps={{ noWrap: true }}
							primary={t("Products")}
						/>
					</ListItem>
					<ListItem
						classes={{ root: "MuiListItem-indicators" }}
						button
						component={NavLink}
						to="/sales"
					>
						<ListItemText
							primaryTypographyProps={{ noWrap: true }}
							primary={t("Sales")}
						/>
					</ListItem>
					<ListItem
						classes={{ root: "MuiListItem-indicators" }}
						button
						component={NavLink}
						to="/customers"
					>
						<ListItemText
							primaryTypographyProps={{ noWrap: true }}
							primary={t("Customers")}
						/>
					</ListItem>
					<ListItem
						classes={{ root: "MuiListItem-indicators" }}
						button
						ref={ref}
						onClick={handleOpen}
					>
						<ListItemText
							primaryTypographyProps={{ noWrap: true }}
							primary={
								<Box display="flex" alignItems="center">
									{t("Management")}
									<Box display="flex" alignItems="center" pl={0.3}>
										<ExpandMoreTwoToneIcon fontSize="small" />
									</Box>
								</Box>
							}
						/>
					</ListItem>
				</List>
			</ListWrapper>
			<Menu anchorEl={ref.current} onClose={handleClose} open={isOpen}>
				<MenuItem sx={{ px: 0 }} component={NavLink} to="/categories" onClick={handleClose}>
					{t("Categories")}
				</MenuItem>
				<MenuItem sx={{ px: 0 }} component={NavLink} to="/brands" onClick={handleClose}>
					{t("Brands")}
				</MenuItem>
				<MenuItem sx={{ px: 0 }} component={NavLink} to="/suggestions" onClick={handleClose}>
					{t("Suggestions")}
				</MenuItem>
				{/* <MenuItem sx={{ px: 3 }} component={NavLink} to="/roles" onClick={handleClose}>
					{t("Roles")}
				</MenuItem>
				<MenuItem sx={{ px: 3 }} component={NavLink} to="/users" onClick={handleClose}>
					{t("Users")}
				</MenuItem> */}
			</Menu>
		</>
	);
}

export default HeaderMenu;
