import DataUsageOutlinedIcon from '@mui/icons-material/DataUsageOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import PhotoFilterOutlinedIcon from '@mui/icons-material/PhotoFilterOutlined';
import QrCodeScannerOutlinedIcon from '@mui/icons-material/QrCodeScannerOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { useTranslation } from "react-i18next";
import FeatureCard from "./FeatureCard";

function Features() {
	const { t } = useTranslation();

	return <>
		<FeatureCard icon={<QrCodeScannerOutlinedIcon />} title={t("ScanBarcodeFeature")} body={t("ScanBarcodeFeatureBody")} />
		<FeatureCard icon={<PhotoFilterOutlinedIcon />} title={t("AiFeature")} body={t("AiFeatureBody")} />
		<FeatureCard icon={<DataUsageOutlinedIcon />} title={t("DashboardFeature")} body={t("DashboardFeatureBody")} />
		<FeatureCard icon={<LocalShippingOutlinedIcon />} title={t("ShippingFeature")} body={t("ShippingFeatureBody")} />
		<FeatureCard icon={<NotificationsNoneOutlinedIcon />} title={t("NotificationFeature")} body={t("NotificationFeatureBody")} />
		<FeatureCard icon={<SendOutlinedIcon />} title={t("TelegramFeature")} body={t("TelegramFeatureBody")} />
	</>;
}

export default Features;
