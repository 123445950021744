import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { HttpContext } from "src/contexts/HttpContextProvider";

function Subscription() {
	const { t } = useTranslation();
	const [subscription, setSubscription] = useState<FixMeLater>({});
	const http = useContext(HttpContext);

	useEffect(() => {
		http.getHttp<FixMeLater>("subscriptions/current").then(res => {
			setSubscription(res.data);
		});
	}, []);


	const terminateSession = (id: string) => {
		http.putHttp("app/revoke?refreshTokenId=" + id).then(_ => {
			setSubscription(old => old.filter(s => s.refreshTokenId !== id));
		});
	}

	return <Card>
		<CardContent>
			<Typography variant="h5" gutterBottom>
				{t("Subscription")}
			</Typography>
			<Typography variant="body1" gutterBottom>
				{t("Your current subscription plan is")} <strong>{subscription.plan?.name}</strong>.
			</Typography>
			<Typography variant="body1" gutterBottom>
				{t("Your billing period ends on")} <strong>{t("December 31, 2023")}</strong>.
			</Typography>
			<Box display="flex" justifyContent="flex-end">
				<Button variant="contained" color="error" sx={{ mt: 2 }}>
					{t("CancelSubscription")}
				</Button>
			</Box>
		</CardContent>
	</Card>;
}

export default Subscription;
