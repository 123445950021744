import { Link, Typography } from "@mui/material";


function OwnerRight() {
    return (
        <Typography
            sx={{
                pt: { xs: 2, md: 0 }
            }}
            variant="subtitle1"
        >
            <Link
                href="https://metin.dev"
                target="_blank"
                rel="noopener"
            >
                metin.dev
            </Link>
        </Typography>
    );
}

export default OwnerRight;
