import StarOutlineRoundedIcon from '@mui/icons-material/StarOutlineRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import { Avatar, Box, CardHeader, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { defaultProduct, getImagePath } from "src/GeneralHelper";

interface IUserReviewProps {
	name: string;
	company: string;
	review: string;
	starRating: number;
}

function UserReview(props: IUserReviewProps) {
	const theme = useTheme();
	const { t } = useTranslation();
	const imagePath = getImagePath(defaultProduct);

	return <Grid item lg={4} xs={12} container justifyContent="center">
		<Box sx={{
			maxWidth: 500, minWidth: 400,
			paddingBottom: 0,
			display: "flex",
			flexDirection: "column",
			justifyContent: "space-between",
			padding: theme.spacing(2)
		}}>
			<Box>
				<Box display="flex" justifyContent="center" alignItems="center">
					{new Array(props.starRating).fill(1).map((_, i) => <StarRoundedIcon key={i} sx={{ color: "#FFE628" }} />)}
					{new Array(5 - props.starRating).fill(1).map((_, i) => <StarOutlineRoundedIcon key={i} sx={{ color: "#FFE628" }} />)}
				</Box>
			</Box>
			<Box sx={{ paddingY: 1 }}>
				<Typography variant="body2" sx={{ color: 'text.secondary' }}>
					{props.review}
				</Typography>
			</Box>
			<Box display="flex" justifyContent="center" alignItems="center" mt="auto">
				<CardHeader
					avatar={
						<Avatar variant="rounded" src="landing.jpg" aria-label="recipe" />
					}
					title={props.name}
					subheader={props.company}
					sx={{ paddingY: 0 }}
				/>
			</Box>
		</Box>
	</Grid>;
}

export default UserReview;
