import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
	en: {
		translation: {
			"ProjectName": "Doyen",
			"Select": "Select",
			"Login": "Login",
			"LoginBtnText": "Login",
			"Email": "E-mail",
			"Password": "Password",
			"Tenant": "Company Name",
			"BestSellers": "Bestsellers",
			"Product": "Product",
			"ProfitBalanceHeader": "{{month}} Earnings",
			"SalesLineChart": "Earnings: ₺",
			"ThisWeek": "This Week",
			"MakeASale": "Sell",
			"Logout": "Logout",
			"AddProduct": "Add New Product",
			"SearchResultsFor": "Search results for <bold>{{searchValue}}</bold>",
			"NoSearchResultsFor": "<bold>{{searchValue}}</bold> couldn't found",
			"Customer": "Customer",
			"SearchCustomerOrProduct": "Search Customer or Product",
			"SearchPlaceholder": "Name / E-mail / Phone / Barcode / Product Title",
			"CancelSale": "Clear",
			"ConfirmSale": "Complete",
			"FindCustomerOrProduct": "Find Customer/Product",
			"AddToCart": "Add to Cart",
			"ConnectionBreak": "Scanner connection broken",
			"AddedToInsertList": "{{count}} product(s) added to insert list",
			"AddedToCart": "{{count}} product(s) added to cart",
			"Notifications": "Notifications",
			"NoNotifications": "No notification",
			"Discount": "Discount",
			"RemoveFromCart": "Remove",
			"TotalProductQuantity": "Total Product Quantity",
			"DiscountPercentage": "Discount Percent",
			"SelectCustomerFirst": "Please select a customer first",
			"SellSuccessful": "Sale successful!",
			"HomePage": "Homepage",
			"Management": "Manage",
			"CustomerNameRequired": "Customer name is required",
			"PriceMissing": "Price is missing",
			"DuplicateBarcode": "This barcode has multiple products",
			"ProjectDescription": "Clothes Inventory & Sale System",
			"CurrentYear": "2024",
			"Address": "Address",
			"Name": "Name",
			"Phone": "Phone",
			"AddNewCustomer": "Add New Customer",
			"MakeASaleCustomerSubtitle": "Please select a customer and use your phone for adding products",
			"Expenses": "Expenses",
			"DeleteConfirmationText": "Delete {{entityName}}?",
			"Record": "Record",
			"Delete": "Delete",
			"Cancel": "Cancel",
			"Expense": "Expense",
			"ExpenseDescription": "Expense",
			"Amount": "Amount",
			"SpentAt": "Spent At",
			"RowsPerPage": "Rows Per Page",
			"PagingLocal": "{{from}}-{{to}} of {{count}}",
			"Customers": "Customers",
			"Brands": "Brands",
			"Description": "Description",
			"Details": "Details",
			"PurchasePricePerItem": "Cost",
			"PurchasePriceTotal": "Total Cost",
			"Insert": "Insert",
			"InsertTitle": "Insert {{entityName}}",
			"UpdateTitle": "Update {{entityName}}",
			"InsertSuccessWithKey": "{{entityName}} inserted successfully.",
			"UpdateSuccessWithKey": "{{entityName}} updated successfully.",
			"Brand": "Brand",
			"Categories": "Categories",
			"Category": "Category",
			"Roles": "Roles",
			"Role": "Role",
			"TotalSpent": "Total Spent",
			"Save": "Save",
			"Edit": "Edit",
			"Sales": "Sales",
			"Note": "Note",
			"Date": "Date",
			"Total": "Total",
			"Products": "Products",
			"Users": "Users",
			"AddNewExpense": "Add New Expense",
			"CartEmpty": "No products in the cart!",
			"ProductSelectorEmptyMessage": "Scan barcodes with your phone to add products quickly",
			"Barcode": "Barcode",
			"Title": "Title",
			"Size": "Size",
			"Color": "Color",
			"StockSize": "Stock",
			"PurchasePrice": "Cost",
			"SellingPrice": "Price",
			"Quantity": "Quantity",
			"EnterPricesAtOnce": "Change all prices at once",
			"EnterPricesOneByOne": "Change prices one by one",
			"ProductItemsErrorsGeneric": "All prices must be filled, price must be greater than cost",
			"LastWeek": "Last Week",
			"Profit": "Profit",
			"Endorsement": "Endorsement",
			"LastWeeksProfitToday": "Last 7 day's profit: {{profit}}",
			"SoldQuantityThisYear": "Bu Senenin Satış Adedi",
			"ConfirmSaleQuestion": "Confirm sale?",
			"PurchaseIsGreatedThanSale": "Cost is greater than selling price!",
			"StockShortage": "Some products are not in stock!",
			"Sale": "Sale",
			"ProductSearchResultDescription": "{{title}}, Size: {{size}}, Color: {{color}}, Stock: {{quantity}}, Price: {{price}}",
			"RequiredField": "This field is required",
			"RequiredFieldSelling": "Price is required",
			"RequiredFieldPurchase": "Cost is required",
			"MustBePositive": "Must be positive",
			"MustBeGreaterThanPurchase": "Price must be greater than cost",
			"IOSWarning": "To be able to use barcode scanning, upgrade IOS version to minimum 16.3.",
			"SuccefullyScanned": "{{count}} product(s) scanned successfully.",
			"Refund": "Refund",
			"RefundItemConfirmationText": "Refund {{entityName}}? This can not be undone!",
			"RefundConfirmationText": "Refund sale completely? This can not be undone!",
			"Search": "Search",
			"MissingBarcodeSystem": "Product with <bold>{{barcode}}</bold> barcode is missing in the system.",
			"FoundMultipleProductsWithBarcode": "Found more than one product with the same barcode.",
			"ProductPhotoMissing": "Product transferred without photo.",
			"ProductTransfered": "Product transferred to the system.",
			"Onboard": "Onboarding",
			"CompanyName": "Company Name",
			"Subdomain": "Subdomain",
			"FullName": "Full Name",
			"OnboardBtnText": "Onboard",
			"InvalidEmail": "Please enter a valid e-mail",
			"MaxLenField": "This field can be maximum {{len}} characters",
			"Migrated": "{{res}} migrated successfully",
			"Migrate": "Migrate",
			"Next": "Next",
			"SubdomainInUse": "This subdomain is in use",
			"RedirectToSite": "Installation successful. You can access management app via <bold>{{url}}</bold> address.",
			"DraftAddedHeader": "New product draft added!",
			"DraftAddedBody": "Product with {{barcode}} added to drafts.",
			"MissingCategoryBody": "Category: {{category}} added to suggestions.",
			"MissingBrandBody": "Brand: {{brand}} added to suggestions.",
			"Clear": "Clear",
			"GiveItATry": "Give it a try, it's free!",
		}
	},
	tr: {
		translation: {
			"ProjectName": "Doyen",
			"Select": "Seçiniz",
			"Login": "Giriş",
			"LoginBtnText": "Giriş Yap",
			"Email": "E-mail",
			"Password": "Şifre",
			"Tenant": "Şirket Adı",
			"BestSellers": "En Çok Satanlar",
			"Product": "Ürün",
			"ProfitBalanceHeader": "{{month}} Ayı Kazancı",
			"SalesLineChart": "Satış: ₺",
			"ThisWeek": "Bu Hafta",
			"MakeASale": "Satış Yap",
			"Logout": "Çıkış Yap",
			"AddProduct": "Ürün Ekle",
			"SearchResultsFor": "<bold>{{searchValue}}</bold> için arama sonuçları",
			"NoSearchResultsFor": "<bold>{{searchValue}}</bold> için sonuç bulunamadı",
			"Customer": "Müşteri",
			"SearchCustomerOrProduct": "Müşteri / Ürün Arama",
			"SearchPlaceholder": "Ad / E-mail / Telefon / Barkod / Ürün Adı",
			"CancelSale": "Temizle",
			"ConfirmSale": "Tamamla",
			"FindCustomerOrProduct": "Müşteri/Ürün Bul",
			"AddToCart": "Sepete Ekle",
			"ConnectionBreak": "Barkod bağlantısı çalışmıyor",
			"AddedToInsertList": "{{count}} ürün eklenmek için hazır",
			"AddedToCart": "{{count}} ürün sepete eklendi",
			"Notifications": "Bildirimler",
			"NoNotifications": "Bildirim yok",
			"Discount": "İndirim",
			"RemoveFromCart": "Sepetten Çıkar",
			"TotalProductQuantity": "Toplam Ürün Sayısı",
			"DiscountPercentage": "İndirim Yüzdesi",
			"SelectCustomerFirst": "Lütfen önce müşteri secin!",
			"SellSuccessful": "Satış başarılı!",
			"HomePage": "Anasayfa",
			"Management": "Yönet",
			"CustomerNameRequired": "Müşteri ismi zorunludur",
			"PriceMissing": "Fiyat bulunamadı",
			"DuplicateBarcode": "Bu barkod birden fazla ürüne karşılık geliyor",
			"ProjectDescription": "Giyim Stok Takip & Satış Sistemi",
			"CurrentYear": "2024",
			"Address": "Adres",
			"Name": "İsim",
			"Phone": "Telefon",
			"AddNewCustomer": "Yeni Müşteri Ekle",
			"MakeASaleCustomerSubtitle": "Satış yapmak için telefonunuzdan ürünleri tarayın ve bir müşteri seçin",
			"Expenses": "Harcamalar",
			"DeleteConfirmationText": "{{entityName}} silinsin mi?",
			"Record": "Kayıt",
			"Delete": "Sil",
			"Cancel": "İptal",
			"Expense": "Harcama",
			"ExpenseDescription": "Harcama",
			"Amount": "Tutar",
			"SpentAt": "Harcama Tarihi",
			"RowsPerPage": "Gösterilen Kayıt Sayısı",
			"PagingLocal": "{{from}}-{{to}} / {{count}}",
			"Customers": "Müşteriler",
			"Brands": "Markalar",
			"Description": "Tanım",
			"PurchasePricePerItem": "Ürün Maliyeti",
			"PurchasePriceTotal": "Toplam Maliyet",
			"Insert": "Ekle",
			"Update": "Güncelle",
			"InsertTitle": "{{entityName}} Ekle",
			"UpdateTitle": "{{entityName}} Güncelle",
			"InsertSuccessWithKey": "{{entityName}} başarıyla eklendi.",
			"UpdateSuccessWithKey": "{{entityName}} başarıyla güncellendi.",
			"Brand": "Marka",
			"Categories": "Kategoriler",
			"Category": "Kategori",
			"Roles": "Roller",
			"Role": "Rol",
			"TotalSpent": "Toplam Harcama",
			"Save": "Kaydet",
			"Edit": "Düzenle",
			"Sales": "Satışlar",
			"Note": "Not",
			"Date": "Tarih",
			"Total": "Toplam",
			"Products": "Ürünler",
			"Users": "Kullanıcılar",
			"AddNewExpense": "Yeni Harcama Ekle",
			"CartEmpty": "Sepette ürün yok!",
			"ProductSelectorEmptyMessage": "Hızlı ürün eklemek için telefonunuzdan barkod tarayabilirsiniz",
			"Barcode": "Barkod",
			"Title": "İsim",
			"Size": "Beden",
			"Color": "Renk",
			"StockSize": "Stok",
			"PurchasePrice": "Alış Fiyatı",
			"SellingPrice": "Satış Fiyatı",
			"Quantity": "Adet",
			"EnterPricesAtOnce": "Fiyatları bir kerede değiştir",
			"EnterPricesOneByOne": "Fiyatları tek tek değiştir",
			"ProductItemsErrorsGeneric": "Bütün alış ve satış fiyatları doldurulmalıdır, satış fiyatı alış fiyatından küçük olamaz",
			"LastWeek": "Geçen Hafta",
			"Profit": "Kâr",
			"Endorsement": "Ciro",
			"LastWeeksProfitToday": "Geçen Hafta Bugüne Kadar: {{profit}}",
			"SoldQuantityThisYear": "Bu Senenin Satış Adedi",
			"ConfirmSaleQuestion": "Satış Tamamlansın mı?",
			"PurchaseIsGreatedThanSale": "Maliyet satış tutarından daha yüksek!",
			"StockShortage": "Stokta bulunmayan ürünler var!",
			"Sale": "Satış",
			"ProductSearchResultDescription": "{{title}}, Beden: {{size}}, Renk: {{color}}, Stok Adedi: {{quantity}}, Satış Fiyatı: {{price}}",
			"RequiredField": "Bu alan zorunludur",
			"RequiredFieldSelling": "Satış fiyatı zorunludur",
			"RequiredFieldPurchase": "Alış fiyatı zorunludur",
			"MustBePositive": "Pozitif olmalı",
			"MustBeGreaterThanPurchase": "Satış fiyatı alış fiyatından daha fazla olmalı",
			"IOSWarning": "Barkod tarama özelliği için lütfen IOS sürümünü minimum 16.3'e yükseltin.",
			"SuccefullyScanned": "{{count}} ürün başarıyla tarandı.",
			"Details": "Detaylar",
			"Refund": "İade Al",
			"RefundItemConfirmationText": "{{entityName}} isimli ürün iade alınsın mı? Bu işlem geri alınamaz!",
			"RefundConfirmationText": "Satış tamamen iade alınsın mı? Bu işlem geri alınamaz!",
			"Search": "Arama",
			"MissingBarcodeSystem": "<bold>{{barcode}}</bold> barkodlu ürün sistemde bulunamadı.",
			"FoundMultipleProductsWithBarcode": "Aynı barkodlu birden fazla ürün bulundu.",
			"ProductPhotoMissing": "Ürün fotoğrafsız aktarıldı.",
			"ProductTransfered": "Ürün sisteme aktarıldı.",
			"Onboard": "Kayıt",
			"CompanyName": "Şirket Adı",
			"Subdomain": "Alt Alan Adı",
			"FullName": "İsim",
			"OnboardBtnText": "Kayıt Ol",
			"InvalidEmail": "Lütfen geçerli bir e-mail adresi girin",
			"MaxLenField": "Bu alan {{len}} karakter uzunluğunda olabilir",
			"Migrated": "{{res}} başarıyla aktarıldı",
			"Migrate": "Sisteme Aktar",
			"Next": "Sonraki",
			"SubdomainInUse": "Alan adı kullanımda",
			"RedirectToSite": "Kurulum başarılı. Yönetim uygulamanıza <bold>{{url}}</bold> adresinden ulaşabilirsiniz.",
			"DraftAddedHeader": "Yeni ürün taslağı!",
			"DraftAddedBody": "{{barcode}} barkodlu ürün taslaklara eklendi.",
			"MissingCategoryHeader": "Yeni kategori!",
			"MissingCategoryBody": "Kategori: {{name}} önerilere eklendi.",
			"MissingBrandHeader": "Yeni marka!",
			"MissingBrandBody": "Marka: {{name}} önerilere eklendi.",
			"Clear": "Temizle",
			"Type": "Tip",
			"Suggestions": "Öneriler",
			"Suggestion": "Öneri",
			"Import": "Aktar",
			"HeroTitle": "Akıllı envanter yönetimi ile mağazanızda devrim yapın",
			"HeroBody": "Sorunsuz entegrasyonlar, yapay zeka destekli yönetim ve zahmetsiz organizasyonla mağazanızı güçlendirin. Daha akıllı bir yarın için bugünü basitleştirin.",
			"GiveItATry": "Deneme fırsatını kaçırmayın, tamemen ücretsiz!",
			"StartForFree": "Ücretsiz Başlayın!",
			"MissingVideoSupport": "Your browser does not support the video tag.",
			"StartButtonSubtitle": "7 günlük ücretsiz deneme, kredi kartına gerek yok",
			"Review1": "Küçük tekstil mağazam için tam ihtiyacım olan şeydi. Envanteri takip etmek çok daha kolay hale geldi ve AI destekli öneriler gerçekten işimi büyütmeme yardımcı oldu. Ayrıca, diğer sistemlerle entegre olması işleri daha da basitleştirdi. Kesinlikle tavsiye ederim!",
			"Review2": "Bu uygulama sayesinde iş süreçlerim çok daha düzenli hale geldi. Müşteri ilişkilerini yönetmek artık çok daha kolay ve verimli. Envanter yönetimi konusunda yaşadığım sorunlar tamamen çözüldü. Küçük bir mağaza işletiyorsanız, bu uygulamayı mutlaka deneyin.",
			"Review3": "Kendi mağazamı yönetmek için birçok uygulama denedim ama bu gerçekten başka bir seviyede. Kullanıcı dostu arayüzü, AI özellikleri ve detaylı raporlama seçenekleri işime büyük katkı sağladı. Tekstil mağazaları için bundan daha iyisini bulamazsınız! Uygulama hakkında daha fazla yorum veya içerik istiyorsanız, söyleyebilirsiniz!",

			"ScanBarcodeFeature": "Barkod Tarama",
			"ScanBarcodeFeatureBody": "Akıllı telefonunuzu güçlü bir barkod tarayıcıya dönüştürün. Ek donanıma gerek duymadan envanter ürünlerini kolayca ekleyin, güncelleyin veya arayın.",
			"AiFeature": "AI Desteği",
			"AiFeatureBody": "Yapay zeka sayesinde ürünlerinizi envantere eklemek artık çok daha kolay! Ürünlerin fotoğrafını yükleyin ve yapay zeka, ürün özelliklerini otomatik olarak tanımlayıp envantere hızlıca eklemenize yardımcı olsun. İşlerinizi hızlandırın ve zamandan tasarruf edin.",
			"DashboardFeature": "Rapor Panoları",
			"DashboardFeatureBody": "İş performansınızın tamamını gözden geçirin. Satış trendlerinden envanter analizlerine kadar her şeyi sezgisel ve özelleştirilebilir panolar aracılığıyla erişilebilir hale getirin.",
			"ShippingFeature": "Kargo Entegrasyonu",
			"ShippingFeatureBody": "Lojistiğinizi kolaylaştırın. Popüler kargo firmalarıyla entegre olun, etiketler oluşturun ve gönderileri doğrudan platform üzerinden takip edin.",
			"NotificationFeature": "Gerçek Zamanlı Bildirimler",
			"NotificationFeatureBody": "Anında bildirimlerle her zaman güncel kalın. Düşük stok uyarılarından yeni sipariş güncellemelerine kadar tüm bildirimlerinizi cihazlarınızdan anında alın.",
			"TelegramFeature": "Telegram Botu",
			"TelegramFeatureBody": "Telegram botumuzla işinize her yerden erişin. Envanter güncellemeleri, satış raporları ve önemli bildirimler Telegram sohbetlerinizde elinizin altında.",

			"PaymentSuccessful": "Ödeme başarılı!",
			"MonthlyOnlyFor": "Aylık sadece",
			"MonthlyDescription": "Yapay zeka destekli mağaza yönetim sistemiyle tanışın! Aylık abonelik planımızla GPT-4 tabanlı görüntü sınıflandırma, işinizi kolaylaştıran akıllı araçlar ve 7/24 destek hizmetine erişim sağlayın. İşletmenizin büyümesi için ihtiyacınız olan her şey bir arada!",
			"SubTotal": "Ara Toplam",
			"VAT": "KDV",
			"RefundPolicy": "İade Koşulları",

			"SessionDevice": "Aygıt: {{os}} ile {{browser}}",
			"SessionLocation": "Konum: {{location}}",
			"ActiveSessions": "Aktif Oturumlar",
		}
	},
	ru: {
		translation: {
			"ProjectName": "Дуайен",
			"Select": "Выбирать",
			"Login": "Авторизоваться",
			"LoginBtnText": "Авторизоваться",
			"Email": "Электронная почта",
			"Password": "Пароль",
			"Tenant": "Название компании",
			"BestSellers": "Бестселлеры",
			"Product": "Продукт",
			"ProfitBalanceHeader": "{{month}} Заработок",
			"SalesLineChart": "Заработок: ₺",
			"ThisWeek": "На этой неделе",
			"MakeASale": "Продавать",
			"Logout": "Выйти",
			"AddProduct": "Добавить новый продукт",
			"SearchResultsFor": "Результаты поиска для <bold>{{searchValue}}</bold>",
			"NoSearchResultsFor": "<bold>{{searchValue}}</bold> не смог найти",
			"Customer": "Клиент",
			"SearchCustomerOrProduct": "Поиск клиента или продукта",
			"SearchPlaceholder": "Имя / Электронная почта / Телефон / Штрих-код / ​​Название продукта",
			"CancelSale": "Прозрачный",
			"ConfirmSale": "Полный",
			"FindCustomerOrProduct": "Найти клиента/продукт",
			"AddToCart": "добавить в корзину",
			"ConnectionBreak": "Соединение со сканером нарушено",
			"AddedToInsertList": "{{count}} продукты добавлены в список вставки",
			"AddedToCart": "{{count}} товар(ы) добавлен в корзину",
			"Notifications": "Уведомления",
			"NoNotifications": "Нет уведомления",
			"Discount": "Скидка",
			"RemoveFromCart": "Удалять",
			"TotalProductQuantity": "Общее количество продукта",
			"DiscountPercentage": "Процент скидки",
			"SelectCustomerFirst": "Пожалуйста, сначала выберите клиента",
			"SellSuccessful": "Продажа успешна!",
			"HomePage": "Домашняя страница",
			"Management": "Управлять",
			"CustomerNameRequired": "Требуется имя клиента",
			"PriceMissing": "Цена отсутствует",
			"DuplicateBarcode": "Этот штрих-код содержит несколько продуктов",
			"ProjectDescription": "Инвентарь одежды",
			"CurrentYear": "2024 год",
			"Address": "Адрес",
			"Name": "Имя",
			"Phone": "Телефон",
			"AddNewCustomer": "Добавить нового клиента",
			"MakeASaleCustomerSubtitle": "Пожалуйста, выберите клиента и используйте свой телефон для добавления продуктов.",
			"Expenses": "Затраты",
			"DeleteConfirmationText": "Удалить {{entityName}}?",
			"Record": "Записывать",
			"Delete": "Удалить",
			"Cancel": "Отмена",
			"Expense": "Расход",
			"ExpenseDescription": "Расход",
			"Amount": "Количество",
			"SpentAt": "Потрачено на",
			"RowsPerPage": "Строков на странице",
			"PagingLocal": "{{from}}-{{to}} из {{count}}",
			"Customers": "Клиенты",
			"Brands": "Бренды",
			"Description": "Описание",
			"Details": "Подробности",
			"PurchasePricePerItem": "Расходы",
			"PurchasePriceTotal": "Общая стоимость",
			"Insert": "Вставлять",
			"InsertTitle": "Вставлять {{entityName}}",
			"UpdateTitle": "Обновлять {{entityName}}",
			"InsertSuccessWithKey": "{{entityName}} вставлен успешно.",
			"UpdateSuccessWithKey": "{{entityName}} Успешно Обновлено.",
			"Brand": "Бренд",
			"Categories": "Категории",
			"Category": "Категория",
			"Roles": "Роли",
			"Role": "Роль",
			"TotalSpent": "Всего потрачено",
			"Save": "Сохранять",
			"Edit": "Редактировать",
			"Sales": "Продажи",
			"Note": "Примечание",
			"Date": "Дата",
			"Total": "Общий",
			"Products": "Продукты",
			"Users": "Пользователи",
			"AddNewExpense": "Добавить новый расход",
			"CartEmpty": "Нет товаров в корзине!",
			"ProductSelectorEmptyMessage": "Сканируйте штрих-коды с помощью телефона, чтобы быстро добавлять товары",
			"Barcode": "Штрих-код",
			"Title": "Заголовок",
			"Size": "Размер",
			"Color": "Цвет",
			"StockSize": "Запас",
			"PurchasePrice": "Расходы",
			"SellingPrice": "Цена",
			"Quantity": "Количество",
			"EnterPricesAtOnce": "Изменить все цены сразу",
			"EnterPricesOneByOne": "Меняйте цены по одному",
			"ProductItemsErrorsGeneric": "Все цены должны быть заполнены, цена должна быть больше себестоимости.",
			"LastWeek": "На прошлой неделе",
			"Profit": "Выгода",
			"Endorsement": "Поддержка",
			"LastWeeksProfitToday": "Прибыль за последние 7 дней: {{profit}}",
			"SoldQuantityThisYear": "Бу Сененин Сатыш Адеди",
			"ConfirmSaleQuestion": "Подтвердить продажу?",
			"PurchaseIsGreatedThanSale": "Стоимость превышает цену продажи!",
			"StockShortage": "Некоторых товаров нет в наличии!",
			"Sale": "Распродажа",
			"ProductSearchResultDescription": "{{title}}, Размер: {{size}}, Цвет: {{color}}, Запас: {{quantity}}, Цена: {{price}}",
			"RequiredField": "Это поле обязательно к заполнению",
			"RequiredFieldSelling": "Требуется цена",
			"RequiredFieldPurchase": "Укажите стоимость",
			"MustBePositive": "Должно быть позитивным",
			"MustBeGreaterThanPurchase": "Цена должна быть больше себестоимости",
			"IOSWarning": "Чтобы иметь возможность использовать сканирование штрих-кода, обновите версию IOS минимум до 16.3.",
			"SuccefullyScanned": "{{count}} продукт(ы) успешно отсканирован.",
			"Refund": "Возвращать деньги",
			"RefundItemConfirmationText": "Возвращать деньги {{entityName}}? ",
			"RefundConfirmationText": "Возвратить продажу полностью? ",
			"Search": "Поиск",
			"MissingBarcodeSystem": "Продукт с <bold>{{barcode}}</bold> штрих-код отсутствует в системе.",
			"FoundMultipleProductsWithBarcode": "Найдено более одного товара с одинаковым штрих-кодом.",
			"ProductPhotoMissing": "Товар передан без фото.",
			"ProductTransfered": "Товар передан в систему.",
			"Onboard": "Регистрация",
			"CompanyName": "Название компании",
			"Subdomain": "Субдомен",
			"FullName": "Полное имя",
			"OnboardBtnText": "На борту",
			"InvalidEmail": "Введите, пожалуйста, действительный адрес электронной почты",
			"MaxLenField": "Это поле может быть максимальным {{len}} персонажи",
			"Migrated": "{{res}} успешно перенесен",
			"Migrate": "Мигрировать",
			"Next": "Следующий",
			"SubdomainInUse": "Этот субдомен используется",
			"RedirectToSite": "Установка прошла успешно.  <bold>{{url}}</bold> адрес.",
			"DraftAddedHeader": "Добавлен проект нового продукта!",
			"DraftAddedBody": "Продукт с {{barcode}} добавлен в черновики.",
			"MissingCategoryBody": "Категория: {{category}} добавлена ​​в предложения.",
			"MissingBrandBody": "Бренд: {{brand}} добавлен в предложения.",
			"Clear": "Прозрачный"
		}
	}
};

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: "tr-TR", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
		// you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
		// if you're using a language detector, do not define the lng option

		interpolation: {
			escapeValue: false // react already safes from xss
		}
	});

export default i18n;