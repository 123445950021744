import { Box, CircularProgress } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { AppContext } from "src/contexts/AppContextProvider";
import { HttpContext } from "src/contexts/HttpContextProvider";
import TopMenuLayout from "./LayoutTopMenu";

export const ProtectedRoute = ({ children }) => {
	const ctx = useContext(AppContext);
	const http = useContext(HttpContext);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		// set current user when page first loads.
		if (!ctx.isLoggedIn) {
			http.getHttp<ICurrentUser>("users/current")
				.then((user) => {
					ctx.setUser(user.data);
				})
				.catch((ex) => { console.log("main get user error:", ex); })
				.finally(() => { setLoading(false); });
		} else {
			setLoading(false);
		}

	}, [http, ctx]);

	if (loading) {
		return (
			<Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
				<CircularProgress />
			</Box>
		);
	}

	if (!ctx.isLoggedIn) {
		// user is not authenticated
		return <Navigate to="/login" replace />;
	}

	return <TopMenuLayout>{children}</TopMenuLayout>;
};
