import DeleteIcon from '@mui/icons-material/Delete';
import { Card, CardContent, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { HttpContext } from "src/contexts/HttpContextProvider";
import { UAParser } from 'ua-parser-js';

function ActiveSessions() {
	const { t } = useTranslation();
	const [sessions, setSessions] = useState<IActiveSession[]>([]);
	const http = useContext(HttpContext);

	useEffect(() => {
		http.getHttp<IActiveSession[]>("users/active-sessions").then(res => {
			setSessions(res.data);
		});
	}, []);

	const parser = new UAParser();

	const parse = (userAgent: string) => {
		parser.setUA(userAgent);
		const os = parser.getOS().toString();
		const browser = parser.getBrowser().name;

		return t("SessionDevice", { browser, os });
	}

	const terminateSession = (id: string) => {
		http.putHttp("app/revoke?refreshTokenId=" + id).then(_ => {
			setSessions(old => old.filter(s => s.refreshTokenId !== id));
		});
	}

	return <Card>
		<CardContent>
			<Typography variant="h5" gutterBottom>
				{t("ActiveSessions")}
			</Typography>
			<List>
				{sessions.map(s =>
					<ListItem>
						<ListItemText
							primary={t("SessionLocation", { location: s.location })}
							secondary={parse(s.userAgent)}
						/>
						<ListItemSecondaryAction>
							<IconButton edge="end" color="error" aria-label="delete"
								onClick={() => terminateSession(s.refreshTokenId)}>
								<DeleteIcon />
							</IconButton>
						</ListItemSecondaryAction>
					</ListItem>
				)}
			</List>
		</CardContent>
	</Card>;
}

export default ActiveSessions;
