import {
	Avatar,
	Box,
	IconButton
} from "@mui/material";
import { useTranslation } from "react-i18next";


interface ILanguageSelectorProps {
	color: string;
}

function LanguageSelector(props: ILanguageSelectorProps) {
	const { t, i18n } = useTranslation();
	const onLanguageSelect = (lang: string) => {
		i18n.changeLanguage(lang);
		//formik.setFieldValue("language", lang);
	}

	const getBorder = (lang: string) => lang === i18n.language ? "2px solid " + props.color : "";

	return (
		<Box gap={1}>
			<IconButton
				sx={{
					color: 'white',
					'&:hover': {
						color: 'white',
						backgroundColor: props.color + "33",
						borderRadius: '50%',
					},
				}}
				onClick={() => onLanguageSelect("en-US")}>
				<Avatar sx={{ width: 24, height: 24, border: getBorder("en-US") }} src="/static/images/logo/gb.svg" />
			</IconButton>
			<IconButton
				sx={{
					color: 'white',
					'&:hover': {
						color: 'white',
						backgroundColor: props.color + "33",
						borderRadius: '50%',
					},
				}}
				onClick={() => onLanguageSelect("tr-TR")}>
				<Avatar sx={{ width: 24, height: 24, border: getBorder("tr-TR") }} src="/static/images/logo/tr.svg" />
			</IconButton>
			<IconButton
				sx={{
					color: 'white',
					'&:hover': {
						color: 'white',
						backgroundColor: props.color + "33",
						borderRadius: '50%',
					},
				}}
				onClick={() => onLanguageSelect("ru-RU")}>
				<Avatar sx={{ width: 24, height: 24, border: getBorder("ru-RU") }} src="/static/images/logo/ru.svg" />
			</IconButton>
		</Box>
	);
}

export default LanguageSelector;
