import { Box, Container, Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AppContext } from "src/contexts/AppContextProvider";
import { HttpContext } from "src/contexts/HttpContextProvider";
import Footer from "src/layouts/Footer";
import ActiveSessions from "./components/Settings/ActiveSessions";
import EditUser from './components/Settings/EditUser';
import Subscription from "./components/Settings/Subscription";

function Settings() {
	const ctx = useContext(AppContext);
	const http = useContext(HttpContext);
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();


	return (
		<>
			{/* TODO: Move this page layout to protected */}
			<Helmet>
				<title>
					{t("ProjectName")} - {t("Settings")}
				</title>
			</Helmet>
			<Box display="flex"
				flexDirection="column"
				minHeight="calc(100vh - 80px)"
				alignItems="center">
				<Box display="flex" flexDirection="column" alignItems="center">
					<Container maxWidth="lg" sx={{ mt: 4 }}>
						<Grid container spacing={4}>
							{/* User Information Card */}
							<Grid item xs={12} md={6}>
								<EditUser />
							</Grid>

							{/* Right Side: Subscription Information and Active Sessions */}
							<Grid item xs={12} md={6}>
								<Grid container spacing={4}>
									{/* Subscription Information Card */}
									<Grid item xs={12}>
										<Subscription />
									</Grid>

									{/* Active Sessions Card */}
									<Grid item xs={12}>
										<ActiveSessions />
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Container>
				</Box>
				<Box sx={{
					flex: 1, display: 'flex',
					flexDirection: 'row'
				}} />
				<Footer />
			</Box>
		</>
	);
}

export default Settings;