import {
	Avatar,
	Grid,
	Typography
} from "@mui/material";
import { useContext } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { AppContext } from "src/contexts/AppContextProvider";
import CopyrightLeft from 'src/layouts/Footer/CopyrightLeft';
import OwnerRight from 'src/layouts/Footer/OwnerRight';
import Features from './components/UserReview/Features';
import LanguageSelector from './components/UserReview/LanguageSelector';
import StartButton from './components/UserReview/StartButton';
import UserReview from './components/UserReview/UserReview';

//https://www.landingfolio.com/inspiration/post/commentguard?category=landing-page
function HomePage() {
	const { t } = useTranslation();
	const hostSplit = window.location.host.split(".");
	const subdomain = hostSplit[0];
	const ctx = useContext(AppContext);
	console.log("subdomain", subdomain);

	if (!(subdomain === "www" || subdomain === "giyim" || subdomain === "localhost:3000"))
		return ctx.isLoggedIn ? (
			isMobile ? (
				<Navigate to="/scan" replace />
			) : (
				<Navigate to="/dashboard" replace />
			)
		) : (<Navigate to="/login" replace />);
	else
		return (
			<Grid container direction="column" justifyContent="stretch"
				alignItems="center" spacing={0} bgcolor="#FFFFFF">
				<Grid container direction="row" bgcolor="#6573c3" px={4}>
					<Grid item xs={6} px={2}>
						<img src="header-logo.png" style={{ height: "50px" }} />
					</Grid>
					<Grid item xs={6} container justifyContent="flex-end" pt={1}>
						<LanguageSelector color="#FFFFFF" />
					</Grid>
				</Grid>
				<Grid container direction="row" sx={{ bgcolor: "#6573c3" }} p={4}>
					<Grid item xs={6}>
						<Grid item xs={12} p={4}>
							{/* Temporary fix for top margin */}
						</Grid>
						<Grid item xs={12} p={2}>
							<Typography color="#F2F5F9" variant="h2" gutterBottom>
								{t("HeroTitle")}
							</Typography>
						</Grid>
						<Grid item xs={12} p={2}>
							<Typography color="#F2F5F9" variant="body1" gutterBottom>
								{t("HeroBody")}
							</Typography>
						</Grid>
						<Grid item xs={12} p={2}>
							<StartButton />
						</Grid>
					</Grid>
					<Grid item xs={6} container justifyContent="center" alignItems="center">
						<Avatar variant="circular" sx={{ width: 400, height: 400 }} alt="landing-image" src="landing.jpg" />
					</Grid>
				</Grid>
				<Grid container direction="row" sx={{
					minHeight: "30px", bgcolor: "#6573c3",
					borderBottomLeftRadius: "100%", borderBottomRightRadius: "100%", overflow: "hidden"
				}}>
					<Grid item xs={12}>
						{/* place holder for bottom rounding */}
					</Grid>
				</Grid>
				<Grid container direction="row" justifyContent="space-between" py={4} px={10}>
					<UserReview company="Bordo Fashion" name="Emre Uvut" starRating={5} review={t("Review1")} />
					<UserReview company="Bordo Fashion" name="Emre Uvut" starRating={5} review={t("Review2")} />
					<UserReview company="Bordo Fashion" name="Emre Uvut" starRating={5} review={t("Review3")} />
				</Grid>
				<Grid container direction="row" justifyContent="space-evenly" py={6} px={10} bgcolor="#F2F5F9">
					<Features />
				</Grid>
				<Grid container direction="row" justifyContent="center" p={4}>
					<video width={isMobile ? "100%" : "60%"} controls>
						<source src="intro.mp4" type="video/mp4" />
						{t("MissingVideoSupport")}
					</video>
				</Grid>

				<Grid container direction="row" sx={{
					minHeight: "30px", bgcolor: "#6573c3",
					borderTopLeftRadius: "100%", borderTopRightRadius: "100%", overflow: "hidden"
				}}>
					<Grid item xs={12}>
						{/* place holder for top rounding */}
					</Grid>
				</Grid>
				<Grid container direction="row" sx={{ bgcolor: "#6573c3" }} px={4}>
					<Grid item xs={12} p={2} display="flex" flexDirection="column" alignItems="center">
						<Typography color="#F2F5F9" variant="h3" gutterBottom>
							{t("GiveItATry")}
						</Typography>
						<StartButton />
					</Grid>
				</Grid>
				<Grid container direction="column" justifyContent="center" alignItems="center" px={4} py={2}>
					<Grid item>
						<Typography align="center">
							Privacy Policy | Terms of Service | Refund Policy | Contact Us
						</Typography>
					</Grid>
					<Grid container direction="row" justifyContent="space-between" alignItems="center" width="100%">
						<Grid item>
							<CopyrightLeft />
						</Grid>
						<Grid item>
							<OwnerRight />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		);
}

export default HomePage;
