import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import {
	Button,
	Typography
} from "@mui/material";
import { useContext } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AppContext } from "src/contexts/AppContextProvider";

function StartButton() {
	const { t } = useTranslation();
	const ctx = useContext(AppContext);
	const navigate = useNavigate();

	const onLoginClick = () => {
		isMobile ? navigate("/scan") : navigate("/dashboard");
	}
	return <>
		{ctx.isLoggedIn ?
			<Button
				startIcon={<HomeRoundedIcon />}
				variant="contained"
				component="span"
				color="success"
				size="large"
				onClick={onLoginClick}>
				{t("Login")}
			</Button>
			:
			<Button
				variant="contained"
				component="span"
				color="success"
				size="large"
				onClick={() => navigate("/onboard")}>
				{t("StartForFree")}
			</Button>
		}
		<Typography color="#F2F5F9" variant="subtitle1"
			sx={{ fontSize: "0.7rem", display: "block", paddingY: "3" }}
			gutterBottom>
			{t("StartButtonSubtitle")}
		</Typography>
	</>;
}

export default StartButton;
