import UploadIcon from '@mui/icons-material/Upload';
import { Avatar, Box, Button, Card, CardContent, InputAdornment, MenuItem, TextField } from "@mui/material";
import { FormikHelpers, useFormik } from "formik";
import { enqueueSnackbar } from "notistack";
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { AppContext } from "src/contexts/AppContextProvider";
import { HttpContext } from "src/contexts/HttpContextProvider";
import { getImagePath } from "src/GeneralHelper";
import { InferType, object, string } from 'yup';
import DoyenTextField from "../Inputs/DoyenTextField";

function EditUser() {
	const { t } = useTranslation();
	const [isPhotoLoading, setIsPhotoLoading] = useState(false);
	const ctx = useContext(AppContext);
	const http = useContext(HttpContext);

	const editSchema = object({
		companyName: string().required(t("RequiredField")),
		fullName: string().required(t("RequiredField")),
		address: string(),
		phoneNumber: string(),
		tenantDefaultLanguage: string(),
	});

	type IEditRequest = InferType<typeof editSchema>;

	const formik = useFormik({
		initialValues: {
			fullName: "",
			companyName: "",
			address: "",
			phoneNumber: "",
			tenantDefaultLanguage: ctx.user.language,
		},
		validationSchema: editSchema,
		validateOnBlur: true,
		validateOnChange: false,
		onSubmit: (values: IEditRequest, { setSubmitting }: FormikHelpers<IEditRequest>) => {
			http.putHttp<IUserDetails>("users/details", values).then(res => {
				setSubmitting(false);
				enqueueSnackbar(t("SettingsSaved"), { variant: "success" });
				updateFormikValues(res.data);
				ctx.setUser({
					...ctx.user,
					companyName: res.data.companyName,
					fullName: res.data.fullName,
					language: res.data.tenantDefaultLanguage,
				});
			}).catch(_ => setSubmitting(false));
		},
	});

	const updateFormikValues = (details: IUserDetails) => {
		formik.setFieldValue("companyName", details.companyName);
		formik.setFieldValue("fullName", details.fullName);
		formik.setFieldValue("address", details.address ?? "");
		formik.setFieldValue("phoneNumber", details.phoneNumber);
		formik.setFieldValue("tenantDefaultLanguage", details.tenantDefaultLanguage);
	};

	// TODO: use loading state
	// load first user data
	useEffect(() => {
		http.getHttp<IUserDetails>("users/details").then(res => {
			updateFormikValues(res.data);
		});
	}, []);

	const handleFileUpload = async (event) => {
		const file = event.target.files[0];
		if (file) {
			const formData = new FormData();
			formData.append('file', file);
			const response = await http.putHttp<IUserDetails>('users/logo', formData);
			ctx.setUser({ ...ctx.user, companyLogo: response.data.companyLogo });
		}
	};


	return <Card>
		<CardContent>
			<Box display="flex" alignItems="center" justifyContent="center" my={2}>
				<Avatar
					sx={{
						width: 150,
						height: 150,
						ml: 8
					}}
					variant="rounded"
					alt={ctx.user.fullName}
					src={getImagePath(ctx.user.companyLogo)}
				/>
				<Button
					variant="contained"
					color="secondary"
					component="label"
					disabled={isPhotoLoading}
					sx={{
						position: 'relative',
						bottom: -55,
						right: 115,
						transform: 'translate(50%, 50%)',
						borderRadius: '50%',
						borderColor: "white",
						borderWidth: "3px",
						minWidth: 0,
						width: 40,
						height: 40,
						padding: 0,
					}}
				>
					<UploadIcon />
					<input type="file" accept="image/jpeg" hidden onChange={handleFileUpload} multiple={false} />
				</Button>
			</Box>
			<Box pt={2}
				component="form"
				onSubmit={formik.handleSubmit}
				autoComplete="off">
				<DoyenTextField
					formik={formik}
					accessor="companyName"
					label={t("CompanyName")}
				/>
				<DoyenTextField
					formik={formik}
					accessor="fullName"
					label={t("FullName")}
				/>
				<DoyenTextField
					formik={formik}
					accessor="phoneNumber"
					label={t("Phone")}
					InputProps={{
						startAdornment: <InputAdornment position="start">+90</InputAdornment>,
					}}
				/>
				<DoyenTextField
					formik={formik}
					accessor="address"
					label={t("Address")}
					multiline
					rows={5}
					style={{ height: "137px" }}
				/>
				<Box display="flex" alignItems="center" justifyContent="space-between" mt={2}>
					<TextField
						name="tenantDefaultLanguage"
						select
						label={t("Language")}
						value={formik.values.tenantDefaultLanguage || ""}
						onChange={formik.handleChange}>
						{[
							<MenuItem key={"en-US"} value={"en-US"}>
								<Box display="flex" alignItems="center">
									<Avatar sx={{ width: 20, height: 20, mr: 2 }} src="/static/images/logo/gb.svg" />
									English
								</Box>
							</MenuItem>,
							<MenuItem key={"tr-TR"} value={"tr-TR"}>
								<Box display="flex" alignItems="center">
									<Avatar sx={{ width: 20, height: 20, mr: 2 }} src="/static/images/logo/tr.svg" />
									Türkçe
								</Box>
							</MenuItem>,
							<MenuItem key={"ru-RU"} value={"ru-RU"} >
								<Box display="flex" alignItems="center">
									<Avatar sx={{ width: 20, height: 20, mr: 2 }} src="/static/images/logo/ru.svg" />
									Русский
								</Box>
							</MenuItem>
						]}
					</TextField>
					<Button
						variant="contained"
						color="success"
						size="large"
						type="submit"
						disabled={formik.isSubmitting}>
						{t("Update")}
					</Button>
				</Box>

			</Box>
		</CardContent>
	</Card>;
}

export default EditUser;
