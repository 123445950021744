import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";


function CopyrightLeft() {
    const { t } = useTranslation();
    return (
        <Typography variant="subtitle1">
            &copy; {t("CurrentYear")} - {t("ProjectDescription")}
        </Typography>
    );
}

export default CopyrightLeft;
