import styled from "@emotion/styled";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import CopyrightLeft from "./CopyrightLeft";
import OwnerRight from "./OwnerRight";

const FooterWrapper = styled(Container)(
	({ theme }: FixMeLater) => `
        margin-top: ${theme.spacing(4)};
`
);

function Footer() {
	const { t } = useTranslation();
	return (
		<FooterWrapper className="footer-wrapper">
			<Box
				pb={4}
				display={{ xs: "block", md: "flex" }}
				alignItems="center"
				textAlign={{ xs: "center", md: "left" }}
				justifyContent="space-between"
			>
				<Box>
					<CopyrightLeft />
				</Box>
				<OwnerRight />
			</Box>
		</FooterWrapper>
	);
}

export default Footer;
