import {
    TextField,
    TextFieldProps
} from "@mui/material";
import { FormikProps } from "formik";

type IDoyenTextFieldProps<T> = {
    accessor: string;
    label: string;
    formik: FormikProps<T>;
} & TextFieldProps;

function DoyenTextField<T>(props: IDoyenTextFieldProps<T>) {
    return <TextField
        {...props}
        sx={{
            "& .MuiFormHelperText-root": {
                marginTop: 0,
                fontSize: "10px"
            }
        }}
        fullWidth
        id={props.accessor}
        name={props.accessor}
        label={props.label}
        value={props.formik.values[props.accessor]}
        onChange={props.formik.handleChange}
        onBlur={props.formik.handleBlur}
        error={props.formik.touched[props.accessor] && Boolean(props.formik.errors[props.accessor])}
        helperText={props.formik.touched[props.accessor] && props.formik.errors[props.accessor] || " "}

    />;
}

export default DoyenTextField;
