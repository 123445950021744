import { AxiosResponse } from "axios";
import { FormikErrors, FormikHelpers, FormikTouched } from "formik";
import { FC, createContext, useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { HttpContext } from "./HttpContextProvider";

type DialogMode = "Insert" | "Update";

export interface IFormikRequest<T extends object> {
	errors: FormikErrors<T>;
	values: T;
	touched: FormikTouched<T>;
	handleChange: {
		(e: React.ChangeEvent<any>): void;
		<T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any>
			? void
			: (e: string | React.ChangeEvent<any>) => void;
	};
}

interface ICrudContextProps {
	/** tracking search requests. */
	searchTrigger: number;
	/** refreshes grids that tracks search trigger. */
	refreshGrid: () => void;
	// /** stores selected model on context for easy use. */
	getSelectedModel<T extends object>(): T | undefined;
	// /** sets selected model on contex for easy use. */
	setSelectedModel<T extends object>(model: T): void;
	dialogOpen: boolean;
	// /** opens or closes dialog  */
	setDialogOpen: (open: boolean) => void;
	configure<T extends object>(controller: string, mesageKey: string /*TODO: key of T*/): void;
	mode: DialogMode;
	controller: string;
	setMode: (md: DialogMode) => void;
	entityName: string;
	// getFormik<T extends object>(): T & IFormikRequest<T>
	onSubmit<T extends object>(values: T, formikHelpers: FormikHelpers<T>): Promise<AxiosResponse<T>>;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CrudContext = createContext<ICrudContextProps>({
	searchTrigger: 0,
	refreshGrid: () => { },
	getSelectedModel: () => undefined,
	setSelectedModel: <T extends object>(model: T) => { },
	dialogOpen: false,
	setDialogOpen: (show: boolean) => { },
	configure: <T extends object>(controller: string, mesageKey: string) => { },
	mode: "Insert",
	setMode: (md: DialogMode) => { },
	controller: "",
	entityName: "",
	// getFormik: <T extends object>() => { return {} as T & IFormikRequest<T> },
	onSubmit: <T extends object>(values: T, formikHelpers: FormikHelpers<T>) => Promise.resolve({} as AxiosResponse<T>),
});

export type PropsWithChildren = {
	children?: React.ReactNode;
};

export const CrudProvider: FC<PropsWithChildren> = ({ children }) => {
	const { t, i18n } = useTranslation();
	const http = useContext(HttpContext);
	const [controller, setController] = useState("");
	const [entityName, setEntityName] = useState("");

	const [mode, setMode] = useState<DialogMode>("Insert");
	const [searchTrigger, setSearchTrigger] = useState(0);
	const [sm, setSm] = useState(undefined);
	const [dialogOpen, setDialogOpen] = useState(false);

	const onSubmit = useCallback(
		(values, formikHelpers) => {
			console.warn("useCallback onSubmit called");
			return mode === "Insert" ? insert(values, formikHelpers) : update(values, formikHelpers);
		},
		[mode, controller, sm?.id, entityName, i18n],
	);

	const refreshGrid = () => {
		setSearchTrigger(st => st + 1);
	};

	const getSelectedModel = <T extends object>() => {
		return sm as T;
	};

	const setSelectedModel = model => {
		// trigger helps with update clicks on same row. without this, consecutive clicks cannot be listened
		setSm({ ...model, smTrigger: (model.smTrigger ?? 0) + 1 });
	};

	// const normalizeMessageKey = <T extends object>(values: T) => {
	// 	var first = entityName.charAt(0);
	// 	if (first === first.toLowerCase() && first !== first.toUpperCase()) {
	// 		return values[entityName];
	// 	} else {
	// 		return entityName;
	// 	}
	// }

	const update = <T extends object>(values: T, formikHelpers: FormikHelpers<T>) => {
		let successMessage = t("UpdateSuccessWithKey", { entityName });
		console.log("update", values);
		return http.putHttp<T, T>(controller + "/" + sm.id, values, successMessage).then(response => {
			formikHelpers.resetForm();
			setDialogOpen(false);
			setSm(undefined);
			refreshGrid();
			return response;
		});
	};

	// const insert = async <T extends BaseResponse>(values: T, formikHelpers: FormikHelpers<T>) => {
	const insert = <T extends object>(values: T, formikHelpers: FormikHelpers<T>) => {
		let successMessage = t("InsertSuccessWithKey", { entityName });
		// console.log("insert", values, successMessage);
		return http.postHttp<T, T>(controller, values, successMessage).then(response => {
			formikHelpers.resetForm();
			setDialogOpen(false);
			setSm(undefined);
			refreshGrid();
			return response;
		});
	};

	const configure = <T extends object>(controller: string, entityName: string): void => {
		setController(controller);
		setEntityName(entityName);
	};

	return (
		<CrudContext.Provider
			value={{
				searchTrigger,
				refreshGrid,
				getSelectedModel,
				setSelectedModel,
				dialogOpen,
				setDialogOpen,
				configure,
				mode,
				setMode,
				controller,
				entityName,
				onSubmit,
			}}>
			{children}
		</CrudContext.Provider>
	);
};
